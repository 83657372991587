import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: false,
  reducers: {
    setUser(state, action) {
      return action.payload;
    },

    updateUser(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },

    logout(state, action) {
      localStorage.removeItem("t");
      localStorage.removeItem("r");
      state = false;
      return state;
    },
  },
});

export const { setUser, updateUser, logout } = userSlice.actions;
export default userSlice.reducer;

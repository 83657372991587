import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setMediaTypes, setCategories, setStaticTexts, setConfig } from "./reduxSlices/config";

import Navigation from "./route";

import sendRequest from "./service";
import { dispatchRef } from "./constants/refs";
import handleError from "./helpers/errorHandler";
import { setUser } from "./reduxSlices/user";
import { checkIsUserActive } from "./helpers/user";
import SplashScreen from "./screens/SplashScreen";
import LoadingScreen from "./components/LoadingScreen";

function App() {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    dispatchRef.current = dispatch;
    initialize();
  }, []); //eslint-disable-line

  window.isMobile = () => {
    return window.innerWidth < 600;
  };

  async function initialize() {
    try {
      const configData = await sendRequest.get("config");

      const config = {};
      configData.data.data.forEach((item) => {
        config[item.keyword] = item.value;
      });
      dispatch(setConfig(config));

      if (localStorage.getItem("static_version") != config.web_static_texts_version) {
        let textsObject = {};
        let staticTexts = await sendRequest.get("translations");
        staticTexts.data.data.forEach((item) => {
          textsObject[item.keyword] = item.value;
        });

        localStorage.setItem("static_version", config.web_static_texts_version);
        dispatch(setStaticTexts(textsObject));
      }

      let user;
      try {
        if (localStorage.getItem("t")) {
          user = await sendRequest.get("user/informations");
          user = user.data;
          user.isActive = checkIsUserActive(user.status);
          dispatch(setUser(user));
        }
      } catch (error) {}

      if (user?.isActive) {
        const medias = await sendRequest.get("media-types");
        dispatch(setMediaTypes(medias.data.data));
        const categories = await sendRequest.get("categories");
        dispatch(setCategories(categories.data.data));
      }
      setReady(true);
    } catch (error) {
      handleError(error);
    }
  }

  if (!ready) return <LoadingScreen />;

  return <Navigation />;
}

export default App;

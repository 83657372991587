import c from "./categoryfield.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AnimatedView from "../../../AnimatedView";

export default function CategoryField({ mediaID, mediaName, setMobileMenu }) {
  const navigate = useNavigate();
  const categories = useSelector((state) => state.config.categories);

  function handleNavigate(item) {
    navigate("/category/" + item.id, { state: { category: item, mediaID, mediaName } });
    setMobileMenu(false);
  }

  return (
    <AnimatedView className={c.categoryField}>
      {categories &&
        categories.map((item) => {
          return (
            <div
              key={"categroy-ittem-" + item.name}
              className={c.categoryButton}
              onClick={() => handleNavigate(item)}
            >
              {item.name}
            </div>
          );
        })}
    </AnimatedView>
  );
}

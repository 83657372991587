import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classcat from "classcat";
import AnimatedView from "../AnimatedView";

import "../../assets/thirdparty/css/bootstrap.min.css";
import "../../assets/css/style.css";
import c from "./header.module.css";
import MediaButton from "./MediaButton";
import { setNetworkModal } from "../../reduxSlices/ui";
import MobileMediaButton from "./MobileMediaButton";
import { navigationRef } from "../../constants/refs";

export default function Header() {
  const dispatch = useDispatch();
  const mediaTypes = useSelector((state) => state.config.mediaTypes);
  const user = useSelector((state) => state.user);
  const [mobileMenu, setMobileMenu] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  navigationRef.current = navigate;

  if (location.pathname === "/video" || location.pathname === "/subscribe") return false;
  return (
    <header
      id="gen-header"
      className="gen-header-style-1 gen-has-sticky"
    >
      <div className="gen-bottom-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a
                  className="navbar-brand"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  <img
                    className="img-fluid logo"
                    src={require("../../assets/images/logo.png")}
                    alt="blkgtv-image"
                  />
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div
                    id="gen-menu-contain"
                    className="gen-menu-contain"
                  >
                    <ul
                      id="gen-main-menu"
                      className="navbar-nav ml-auto"
                    >
                      {user.isActive && (
                        <>
                          {!window.isMobile() && (
                            <li>
                              <div
                                onClick={() => navigate("/search")}
                                className={classcat([
                                  c.headerButton,
                                  window.location.pathname === "/search" && c.active,
                                ])}
                              >
                                Search
                              </div>
                            </li>
                          )}
                          <li className="menu-item active">
                            <div
                              onClick={() => navigate("/")}
                              className={classcat([
                                c.headerButton,
                                window.location.pathname === "/" && c.active,
                              ])}
                            >
                              Home
                            </div>
                          </li>
                          {mediaTypes &&
                            mediaTypes.map((media) => {
                              return (
                                <MediaButton
                                  key={"media-item-" + media.id}
                                  media={media}
                                />
                              );
                            })}

                          <li className="menu-item">
                            <a onClick={() => navigate("/my-list")}>My List </a>
                          </li>
                        </>
                      )}

                      <li className="menu-item active">
                        <a
                          onClick={
                            user
                              ? () => dispatch(setNetworkModal(true))
                              : () => navigate("/join-the-network")
                          }
                          className={c.joinTheNetwork}
                          aria-current="page"
                        >
                          Join the Network
                        </a>
                      </li>

                      {!user && (
                        <li className="menu-item">
                          <a
                            onClick={() => navigate("/sign-up")}
                            className={c.signUp}
                            aria-current="page"
                          >
                            Sign Up
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="gen-header-info-box">
                  {user?.isActive && window.isMobile() && (
                    <div
                      onClick={() => {
                        navigate("/search");
                        setMobileMenu(false);
                      }}
                      className="gen-menu-search-block"
                    >
                      <a style={{ width: 28 }}>
                        <img src={require("../../assets/images/header-search.png")} />
                      </a>
                    </div>
                  )}
                  {user && (
                    <div
                      style={{ width: 37 }}
                      className="gen-account-holder"
                    >
                      <a
                        onClick={() => {
                          navigate("/profile");
                          setMobileMenu(false);
                        }}
                        style={{
                          background: "none",
                          width: "50px",
                          height: "50px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                            objectFit: "cover",
                          }}
                          src={require("../../assets/images/header-user.png")}
                        />
                      </a>
                    </div>
                  )}
                  {window.isMobile() && !user && (
                    <a
                      style={{
                        marginRight: 10,
                      }}
                      onClick={
                        user
                          ? () => dispatch(setNetworkModal(true))
                          : () => navigate("/join-the-network")
                      }
                      className={c.joinTheNetwork}
                      aria-current="page"
                    >
                      Join the Network
                    </a>
                  )}
                  {!user && window.isMobile() && (
                    <a
                      style={{ padding: 7 }}
                      onClick={() => navigate("/sign-up")}
                      className={c.signUp}
                    >
                      Sign Up
                    </a>
                  )}
                </div>
                {user && (
                  <button
                    onClick={() => setMobileMenu((val) => !val)}
                    style={{ background: "none" }}
                    className="navbar-toggler"
                  >
                    <img src={require("../../assets/images/hamburger.png")} />
                  </button>
                )}
                {mobileMenu && (
                  <AnimatedView className={c.mobileDropdown}>
                    {user?.isActive &&
                      mediaTypes &&
                      mediaTypes.map((media) => {
                        return (
                          <MobileMediaButton
                            key={"media-item-" + media.id}
                            media={media}
                            setMobileMenu={setMobileMenu}
                          />
                        );
                      })}
                    <div
                      onClick={() => {
                        setMobileMenu(false);
                        navigate("/my-list");
                      }}
                      className={c.mobileHeaderButton}
                    >
                      My List
                    </div>
                  </AnimatedView>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

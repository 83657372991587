import React, { useEffect, useState } from "react";
import { payments } from "@square/web-sdk";
import Button from "../../components/Button";
import c from "./paymentpage.module.css";
import sendRequest from "../../service";
import { Link, useLocation, useParams } from "react-router-dom";
import { navigate } from "../../constants/refs";
import handleError from "../../helpers/errorHandler";
import { useDispatch } from "react-redux";
import { setLoading, setModal } from "../../reduxSlices/ui";
import { updateUser } from "../../reduxSlices/user";
import LoadingScreen from "../../components/LoadingScreen";

let progress = false;
const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [paymentsInstance, setPaymentsInstance] = useState(null);
  const [card, setCard] = useState(null);

  const newCardPage = location.pathname === "/payment/add-card";

  const plans = {
    standard: {
      name: "Standard",
      price: "8.99",
    },
    premium: {
      name: "Premium",
      price: "11.99",
    },
  };

  useEffect(() => {
    if (!newCardPage && !plans[params.plan]) navigate(-1);
    if (!paymentsInstance) {
      initializeSquare();
    }
  }, []);

  async function initializeSquare() {
    const paymentsInstance = await payments(
      process.env.REACT_APP_SQUARE_APP_ID,
      process.env.REACT_APP_SQUARE_LOCATION_ID
    );

    const cardInstance = await paymentsInstance.card();
    await cardInstance.attach("#card-container");

    setPaymentsInstance(paymentsInstance);
    setCard(cardInstance);
  }

  const handleSubscription = async () => {
    if (progress) return;

    try {
      dispatch(setLoading(true));
      progress = true;
      const nonceResponse = await card.tokenize();
      if (nonceResponse.status === "OK") {
        const token = nonceResponse.token;

        if (newCardPage) {
          await sendRequest.post("user/cards/create", {
            p_token: token,
          });

          dispatch(
            setModal({
              type: "success",
              title: "Card Added",
              message: "Your new card will be used for subscription.",
              button: {
                text: "Okay",
              },
            })
          );
          navigate("/profile");
        } else {
          await sendRequest.post("subscription/subscribe", {
            p_token: token,
            plan: params.plan,
          });

          dispatch(
            updateUser({
              isActive: true,
              status: "subscribed",
            })
          );
          navigate("/payment/success", { replace: true });
        }
      } else {
        handlePaymentError(nonceResponse.errors);
        console.error("Tokenization failed:", nonceResponse.errors);
      }
    } catch (error) {
      handlePaymentError(error);
    }
    dispatch(setLoading(false));
    progress = false;
  };

  function handlePaymentError(error) {

    if (
      error.response?.data?.message &&
      error.response?.data?.message[0] &&
      error.response?.data?.message[0]?.code === "INVALID_CARD_DATA"
    ) {
      dispatch(
        setModal({
          type: "failed",
          title: "Invalid Card",
          message: "Please check your card informations",
          button: {
            text: "Okay",
          },
        })
      );
    } else {
      dispatch(
        setModal({
          type: "failed",
          title: "Payment Unsuccessful",
          message: "Please try again later",
          button: {
            text: "Okay",
          },
        })
      );
    }
  }

  if (!newCardPage && !plans[params.plan]) return null;

  return (
    <div className={c.page}>
      <div className={c.left}>
        {!paymentsInstance && <LoadingScreen />}
        {/* <Link
          className={c.backButton}
          to={-1}
        >
          <img
            src={require("../../assets/images/player/back.png")}
            style={c.backIcon}
            alt="back"
          />
          Back
        </Link> */}
        <img
          class={c.logoField}
          src={require("../../assets/images/logo.png")}
          alt="blkgtv-image"
        />
        {newCardPage ? (
          <>
            <h4>Add New Card</h4>
            <div style={{ paddingRight: "10%" }}>
              Your card will be used on subscription payment for each month. You can delete your
              card or cancel your subscription any time
            </div>
          </>
        ) : (
          <>
            <h4>Subscribe to BLKG {plans[params.plan].name}</h4>
            <div className={c.priceField}>
              <h1>{plans[params.plan].price}$</h1>
              <div className={c.priceLabel}>
                per<div style={{ marginTop: -12 }}>month</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={c.right}>
        <div
          className={c.cardContainer}
          id="card-container"
        ></div>
        <Button
          text={newCardPage ? "Add Card" : "Subscribe"}
          style={c.button}
          onClick={handleSubscription}
        />
      </div>
    </div>
  );
};

export default SubscriptionForm;

import { useEffect } from "react";
import classcat from "classcat";

import c from "./animatedview.module.css";

export default function AnimatedView({ children, style, className, isPage, onClick }) {
  useEffect(() => {
    if (isPage) {
      window.scrollTo(0, 0);
    }
  }, []); //eslint-disable-line

  return (
    <div
      className={classcat([c.view, isPage && c.minHeight, className])}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

import classcat from 'classcat';
import c from './input.module.css';

export default function Input({ 
    style,
    placeholder,
    warn,
    warnText,
    value, 
    onChange,
    containerStyle,
    type,
    maxLength,
    onKeyDown
  }) {
  return (
      <div className={classcat([c.inputField, containerStyle])}>
        <input 
          maxLength={maxLength}
          className={classcat([
            c.input, 
            warn && c.warnInput,
            style
          ])}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
        {
          warn && (
            <div className={c.warnText}>{warnText}</div>
          )
        }
      </div>
  )
}
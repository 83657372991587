import { dispatchRef } from "../constants/refs";
import { setCategories, setMediaTypes } from "../reduxSlices/config";
import { setUser } from "../reduxSlices/user";
import sendRequest from "../service";
import handleError from "./errorHandler";

export async function handleLoginAction(data) {
  try {
    // localStorage.setItem("user", JSON.stringify(res.data.data.user));
    localStorage.setItem("t", data.token.access_token);
    localStorage.setItem("r", data.token.refresh_token);
    data.user.isActive = checkIsUserActive(data.user.status);
    dispatchRef.current(setUser(data.user));

    const medias = await sendRequest.get("media-types");
    const categories = await sendRequest.get("categories");
    dispatchRef.current(setCategories(categories.data.data));
    dispatchRef.current(setMediaTypes(medias.data.data));
  } catch (error) {
    handleError(error);
  }
}

export function checkIsUserActive(status) {
  return ["subscribed", "cancelled", "charge_failed", "free_trial"].includes(status);
}

function handleLogout() {}

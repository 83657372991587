import React, { useState } from "react";

import "../../assets/thirdparty/css/bootstrap.min.css";
import "../../assets/css/style.css";
import pjson from "../../../package.json";

import c from "./footer.module.css";
import Input from "../Input";
import Button from "../Button";
import sendRequest from "../../service";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setModal, setNetworkModal } from "../../reduxSlices/ui";
import { useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config.configData);
  const location = useLocation();
  const navigate = useNavigate();

  const [attempted, setAttempted] = useState(false);
  const [email, setEmail] = useState("");

  function handleApplyNewsletter() {
    setAttempted(true);

    if (!email) return;

    dispatch(setLoading(true));
    sendRequest
      .post("form/newsletter", {
        email,
        user_id: user && user.id,
      })
      .then((res) => {
        dispatch(
          setModal({
            type: "success",
            title: "Success",
            message: "Now you will be able to see the news from us in your e-mail",
            button: {
              text: "Okay",
            },
          })
        );
      })
      .catch((err) => {
        dispatch(
          setModal({
            type: "failed",
            title: "Failed",
            message: err.message,
            button: {
              text: "Okay",
            },
          })
        );
      })
      .finally(() => {
        setAttempted(false);
        dispatch(setLoading(false));
        setEmail("");
      });
  }

  if (location.pathname === "/video" || location.pathname === "/subscribe") return false;
  return (
    <footer className={c.footer}>
      <div className={c.networkField}>
        <img
          src={require("../../assets/images/footer/footer-bear.png")}
          className={c.bear}
        />

        <a
          onClick={() => dispatch(setNetworkModal(true))}
          className={c.joinButton}
        >
          Join the Network
        </a>
      </div>

      <div className={c.middle}>
        <div className={c.newsletter}>BLKG Newsletter</div>

        <div className={c.inputField}>
          <Input
            style={c.input}
            placeholder={"email address"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            warn={attempted && !email}
            warnText={"Email must be filled"}
          />

          <Button
            text={"Subscribe"}
            style={c.subscribeButton}
            onClick={handleApplyNewsletter}
          />
        </div>

        {/* <div className={c.download}> 
          Download App
        </div>

        <div className={c.inputField}> 
          <img
            src={require('../../assets/images/footer/google-play.png')}
          />

          <img
            src={require('../../assets/images/footer/app-store.png')}
          />
        </div> */}
      </div>

      <ul className={c.links}>
        <li onClick={() => navigate("/contact")}>Contact</li>

        <li onClick={() => navigate("/faq")}>FAQ</li>

        <li
          onClick={() =>
            navigate("/legal/privacy-policy", {
              state: {
                html: "privacy-policy",
                title: "Privacy Policy",
              },
            })
          }
        >
          Privacy Policy
        </li>

        <li
          onClick={() =>
            navigate("/legal/terms-of-use", {
              state: {
                html: "terms-of-use",
                title: "Terms of Use",
              },
            })
          }
        >
          Terms of Use
        </li>
      </ul>

      <div className={c.bottom}>
        <img src={require("../../assets/images/logo.png")} />

        <div className={c.subtitle}>Independent Movies, Web Shows & More!</div>

        <div className={c.socials}>
          <a
            href={config.twitter_url}
            target="_blank"
          >
            <img
              src={require("../../assets/images/footer/footer-twitter.png")}
              className={c.social}
            />
          </a>

          <a
            href={config.facebook_url}
            target="_blank"
          >
            <img
              src={require("../../assets/images/footer/footer-face.png")}
              className={c.social}
            />
          </a>

          <a
            href={config.instagram_url}
            target="_blank"
          >
            <img
              src={require("../../assets/images/footer/footer-insta.png")}
              className={c.social}
            />
          </a>

          <a
            href={config.youtube_url}
            target="_blank"
          >
            <img
              src={require("../../assets/images/footer/youtube.png")}
              className={c.social}
            />
          </a>
        </div>
      </div>
      <div className={c.version}>v{pjson.version}</div>
    </footer>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    mediaTypes: false,
    categories: false,
    staticTexts: false,
    configData: false,
    parentalControl: false,
  },
  reducers: {
    setMediaTypes(state, action) {
      state.mediaTypes = action.payload;
      return state;
    },
    setStaticTexts(state, action) {
      state.staticTexts = action.payload;
      return state;
    },
    setCategories(state, action) {
      state.categories = action.payload;
      return state;
    },
    setConfig(state, action) {
      state.configData = action.payload;
      return state;
    },
    setParentalControl(state, action) {
      state.parentalControl = action.payload;
      return state;
    },
  },
});

export const { setMediaTypes, setCategories, setStaticTexts, setConfig, setParentalControl } =
  configSlice.actions;
export default configSlice.reducer;

import React from 'react';
import ReactDOM from 'react-dom/client';

import Swiper from 'swiper';
import 'swiper/css';

import { Provider } from 'react-redux' 
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';

import userReducer from './reduxSlices/user';
import uiReducer from './reduxSlices/ui';
import configReducer from './reduxSlices/config';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
});

const rootReducer = combineReducers({ 
  user: userReducer,
  ui: uiReducer,
  config: configReducer
});

const persistConfig = {
  key: 'blkgtv_react',
  storage,
  blacklist: ["ui", "user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
})

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
  // </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

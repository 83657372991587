import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classcat from 'classcat';
import { useNavigate } from 'react-router-dom';

import c from './mobilebutton.module.css';
import CategoryField from './CategoryField';

export default function MobileMediaButton({ media, setMobileMenu }) {

  const navigate = useNavigate();
  const [more, setMore] = useState(false);
  const dispatch = useDispatch();

  return (
    <div 
      className={classcat([c.headerButtonBox, window.location.pathname === "/" + media.id && c.active])}
     >
        <div className={c.headerButton}>
          <div 
            className={c.name}
            onClick={
              () => {
                navigate('/' + media.id, { replace: true, state: media })
                setMobileMenu(false)
              }
            }
          >
            {media.name}
          </div>

          <img
            onClick={() => setMore(val => !val)}
            className={c.arrow}
            src={ more ? require('../../../assets/images/arrow-up.png') : require('../../../assets/images/arrow-down.png')}
          />
        </div>
        {
          more && (
            <CategoryField
              mediaID={media.id}
              mediaName={media.name}
              setMobileMenu={setMobileMenu}
            />
          )
        }
      </div>
  )
}  
import axios from "axios";
import { dispatchRef, navigate } from "../constants/refs";
import { logout, setUser, updateUser } from "../reduxSlices/user";
import { setModal } from "../reduxSlices/ui";

let fetchTimes, lastCall;

const sendRequest = {
  post: function (url, data, config) {
    return instance.request({
      url: url,
      method: "post",
      data: data,
      headers: {
        ...config,
      },
    });
  },
  delete: function (url, data, config) {
    return instance.request({
      url: url,
      method: "delete",
      data: data,
    });
  },
  get: function (url, data, config) {
    return instance.request({
      url: url,
      method: "get",
      data: data,
    });
  },
};

export default sendRequest;
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-platform": "web",
    "x-language": "en",
    "x-language-id": 1,
  },
});

function handleChargeFailed() {
  dispatchRef.current(
    updateUser({
      status: "passive",
    })
  );
}

function handleLogout() {
  dispatchRef.current(logout());
  navigate("/");
}

instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("t");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    let countdown = response.headers["x-charge-countdown"];
    if (countdown && countdown != sessionStorage.getItem("charge_countdown")) {
      dispatchRef.current(
        setModal({
          title: "Unable to retrieve payment",
          message:
            "We are not able to retrieve your payment #day# later you will not be able to access. Please check your payment method.".replace(
              "#day#",
              countdown
            ),
          button: {
            text: "Check Payment Method",
            onPress: () => navigate("profile", { state: { tab: 2 } }),
          },
          cancel: {
            text: "Later",
          },
        })
      );

      sessionStorage.setItem("charge_countdown", countdown);
    }
    return response;
  },
  async function (error) {
    if (error.response.status === 402) {
      handleChargeFailed();
      return Promise.reject(error);
    } else if (error.response.status === 401 && !error.config.url.includes("refresh-token")) {
      try {
        if (lastCall === error.config.url) fetchTimes++;

        if (fetchTimes >= 2) {
          lastCall = null;
          fetchTimes = 0;

          handleLogout();
          return Promise.reject(error);
        } //son hatalı istek bir sonraki istekte tekrar çağırıldımı diye kontrol etmek için kayda alınıyor

        console.log("fetch times", fetchTimes);

        lastCall = error.config.url;
        console.log("@requesting new token");
        const tokenRes = await instance.request({
          url: "refresh-token",
          method: "post",
          data: {
            refresh_token: localStorage.getItem("r"),
          },
        });

        localStorage.setItem("t", "ozan");
        localStorage.setItem("r", tokenRes.data.data.refresh_token);

        let requestConfig = error.config;
        requestConfig.headers.Authorization = "Bearer " + tokenRes.data.data.access_token;
        //401 üzeriden başarılı sonuç geldiği takdirde sessionstorage kısmındaki döngüyü engelleyen değerler sıfırlanıyor.
        const repeat = await instance.request(requestConfig);
        return Promise.resolve(repeat);
      } catch (error) {
        console.log("@error", error);
        handleLogout();
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

import classcat from "classcat";
import c from "./button.module.css";

export default function Button({ primary, secondary, text, style, icon, isLoading, onClick }) {
  return (
    <button
      className={classcat([c.button, primary && c.primary, secondary && c.secondary, style])}
      onClick={onClick}
    >
      {icon && (
        <img
          src={icon}
          className={c.icon}
          alt=""
        />
      )}
      {text}
      {isLoading && (
        <img
          src={"https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"}
          className={c.loadingIcon}
        />
      )}
    </button>
  );
}

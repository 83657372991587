import { createRef } from "react";

export const deviceId = createRef();
export const tokenRef = createRef();
export const storeRef = createRef();
export const dispatchRef = createRef();
export const userRef = createRef();
export const socket = createRef();
export const safeArea = createRef();
export const navigationRef = createRef();
export const langRef = createRef();

export function navigate(name, params) {
  if (navigationRef.current) navigationRef.current(name, params);
}

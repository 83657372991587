import classcat from "classcat";
import c from "./../header.module.css";
import { useNavigate } from "react-router-dom";
import CategoryField from "../CategoryField";

export default function MediaButton({ media }) {
  const navigate = useNavigate();

  return (
    <li
      className={classcat([
        "menu-item",
        c.headerButton,
        window.location.pathname === "/" + media.id && c.active,
      ])}
    >
      <a onClick={() => navigate("/" + media.id, { replace: true, state: media })}>{media.name}</a>
      <CategoryField
        mediaID={media.id}
        mediaName={media.name}
      />
    </li>
  );
}

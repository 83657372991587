import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "ui",
  initialState: {
    codeModal: false,
    networkModal: false,
    loading: false,
    modal: false,
    pincode: false,
    parentalControl: false
  },
  reducers: {
    setCodeModal(state, action){
      state.codeModal = action.payload;
      return state;
    },
    setNetworkModal(state, action){
      state.networkModal = action.payload;
      return state;
    },
    setLoading(state, action){
      state.loading = action.payload;
      return state;
    },

    setModal(state, action) {
      state.modal = action.payload
    
      return state;
    },
    setPincode(state, action){
      state.pincode = action.payload;
      return state;
    },
    setParentalControl(state, action){
      state.parentalControl = action.payload;
      return state;
    },
  },
});

export const { 
  setCodeModal, 
  setLoading, 
  setModal, 
  setNetworkModal,
  setPincode,
  setParentalControl
} = userSlice.actions;
export default userSlice.reducer;

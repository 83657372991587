import React from "react";

import logo from "./../../assets/images/splash-logo.png";
import c from "./loading.module.css";

export default function LoadingScreen() { 
  return (
    <div className={c.loading}>
      <img src={logo} alt="logo"/>
    </div>
  )
}
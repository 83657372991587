import React, { Suspense } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useSelector } from "react-redux";
import PaymentPage from "./screens/PaymentPage";

import Header from "./components/Header";
import Footer from "./components/Footer";

const Login = React.lazy(() => import("./screens/Login"));
const ForgotPassword = React.lazy(() => import("./screens/ForgotPassword"));

const Home = React.lazy(() => import("./screens/Home"));
const Detail = React.lazy(() => import("./screens/Detail"));
const DetailMulti = React.lazy(() => import("./screens/DetailMulti"));
const MyList = React.lazy(() => import("./screens/MyList"));
const Search = React.lazy(() => import("./screens/Search"));
const Profile = React.lazy(() => import("./screens/Profile"));
const PaymentSuccess = React.lazy(() => import("./screens/PaymentSuccess"));
const PaymentFailed = React.lazy(() => import("./screens/PaymentFailed"));
const Pincode = React.lazy(() => import("./components/Pincode"));
const CodeModal = React.lazy(() => import("./components/CodeModal"));
const NetworkPopup = React.lazy(() => import("./components/NetworkPopup"));
const Modal = React.lazy(() => import("./modals/Modal"));
const ResetPassword = React.lazy(() => import("./screens/ResetPassword"));
const NotFound = React.lazy(() => import("./screens/NotFound"));
const VideoPage = React.lazy(() => import("./screens/Video"));
const LoadingScreen = React.lazy(() => import("./components/LoadingScreen"));
const FAQ = React.lazy(() => import("./screens/FAQ"));
const CategoryList = React.lazy(() => import("./screens/CategoryList"));
const HTMLContent = React.lazy(() => import("./screens/HTMLContent"));
const WelcomePage = React.lazy(() => import("./screens/WelcomePage"));
const JoinTheNetwork = React.lazy(() => import("./screens/JoinTheNetwork"));
const Contact = React.lazy(() => import("./screens/Contact"));

export default function Navigation(params) {
  const user = useSelector((state) => state.user);

  const loading = useSelector((state) => state.ui.loading);
  const network = useSelector((state) => state.ui.networkModal);
  const pincode = useSelector((state) => state.ui.pincode);

  return (
    <BrowserRouter>
      <Suspense>
        <Header />
        <Routes>
          {!user && (
            <>
              <Route
                path="*"
                element={<WelcomePage />}
              />
              <Route
                path="/"
                element={<WelcomePage />}
                exact
              />
              <Route
                path="/join-the-network"
                element={<JoinTheNetwork />}
                exact
              />
              <Route
                path="/sign-up"
                element={<Login />}
                exact
              />
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
                exact
              />
              <Route
                path="/set-password"
                element={<ResetPassword />}
                exact
              />
            </>
          )}

          {user?.isActive ? (
            <>
              <Route
                path="*"
                element={<NotFound />}
              />
              <Route
                path="/contact"
                element={<Contact />}
                exact
              />
              <Route
                path="/detail/:id"
                element={<Detail />}
                exact
              />
              <Route
                path="/detail-multi/:id"
                element={<DetailMulti />}
                exact
              />
              <Route
                path="/my-list"
                element={<MyList />}
                exact
              />
              <Route
                path="/search"
                element={<Search />}
                exact
              />
              <Route
                path="/video"
                element={<VideoPage />}
                exact
              />
              <Route
                path="/category/:id"
                element={<CategoryList />}
                exact
              />
              <Route
                path="/profile"
                element={<Profile />}
                exact
              />
              <Route
                path="/pincode"
                element={<Profile />}
                exact
              />
              <Route
                path="/:id"
                element={<Home />}
                exact
              />
              <Route
                path="/"
                element={<Home />}
                exact
              />
              <Route
                path="/payment/success"
                element={<PaymentSuccess />}
                exact
              />
              <Route
                path="/payment/canceled"
                element={<PaymentFailed />}
                exact
              />
              <Route
                path="/payment/add-card"
                element={<PaymentPage />}
                exact
              />
            </>
          ) : (
            <>
              <Route
                path="*"
                element={<NotFound />}
              />
              <Route
                path="/"
                element={<WelcomePage />}
                exact
              />
              <Route
                path="/subscribe/:plan"
                element={<PaymentPage />}
                exact
              />
              <Route
                path="/payment/canceled"
                element={<PaymentFailed />}
                exact
              />
              <Route
                path="/profile"
                element={<Profile />}
                exact
              />
              <Route
                path="/payment/success"
                element={<PaymentSuccess />}
                exact
              />
              <Route
                path="/profile"
                element={<Profile />}
                exact
              />
            </>
          )}
          <Route
            path="/legal/:id"
            element={<HTMLContent />}
            exact
          />
          <Route
            path="/faq"
            element={<FAQ />}
            exact
          />
          <Route
            path="/contact"
            element={<Contact />}
            exact
          />
        </Routes>
        <Footer />
        <Modal />
        <CodeModal />
        {network && <NetworkPopup />}
        {loading && <LoadingScreen />}
        {pincode && <Pincode />}
      </Suspense>
    </BrowserRouter>
  );
}

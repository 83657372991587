export default function handleError(error, action) {

  // window.context.setPopup({
  //   title: "Unsuccess",
  //   message: action.response.data.message,
  //   button: {
  //     text: "Okay"
  //   },
  //   onClose: action
  // })
  console.log(error);
}